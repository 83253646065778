<template>
    <div class="container-fluid">
        <h5>调 解 案 件<br/>MEDIATION CASE</h5>
        <div>
            <b-list-group>
                <b-list-group-item active>调解信息</b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">调解案号：</b-col>
                        <b-col>{{ caseDetail.caseCode }}</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">被调解人：</b-col>
                        <b-col>{{ caseDetail.borrowerName }}</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">诉讼标的：</b-col>
                        <b-col>{{ caseDetail.mediatePrincipal }}</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">受理阶段：</b-col>
                        <b-col>诉前调解</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">调解员：</b-col>
                        <b-col>{{ caseDetail.mediateUserName }}</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col cols="3">联系电话：</b-col>
                        <b-col>{{ caseDetail.mediateUserPhone }}</b-col>
                    </b-row>
                </b-list-group-item>
                <b-list-group-item>
                    <b-button variant="link" style="color: red; font-weight: bolder;" @click="generateReport">★★★点击下载调解告知函★★★</b-button>
                </b-list-group-item>
            </b-list-group>

            <b-list-group>
                <b-list-group-item active></b-list-group-item>
                <b-list-group-item>
                    <b-row>
                        <b-col class="padLR0" style="line-height: 2rem;">调解阶段:</b-col>
                        <b-col class="padLR0"><b-button size="sm" variant="primary">接受调解</b-button></b-col>
                        <b-icon-play-fill></b-icon-play-fill>
                        <b-col class="padLR0"><b-button size="sm" variant="outline-primary">正式调解</b-button></b-col>
                        <b-icon-play-fill></b-icon-play-fill>
                        <b-col class="padLR0"><b-button size="sm" variant="outline-primary">调解结案</b-button></b-col>
                    </b-row>
                    <b-row>
                        <b-col style="padding-right: 10%; padding-left:35%;">
                            <table class="line-table">
                                <tr>
                                    <td></td>
                                    <td>&nbsp;</td>
                                    <td></td>
                                </tr>
                            </table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col style="padding-right: 10%; padding-left:35%;">
                            <table class="line-table-2">
                                <tr>
                                    <td>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td></td>
                                </tr>
                            </table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="padLR0" style="line-height: 2rem;">诉讼阶段:</b-col>
                        <b-col class="padLR0"><b-button size="sm" variant="outline-primary">调解未果</b-button></b-col>
                        <b-icon-play-fill></b-icon-play-fill>
                        <b-col class="padLR0"><b-button size="sm" variant="outline-primary">诉讼申请</b-button></b-col>
                        <b-icon-play-fill></b-icon-play-fill>
                        <b-col class="padLR0"><b-button size="sm" variant="outline-primary">正式立案</b-button></b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>

            <b-list-group>
                <b-list-group-item active>关于调解中心</b-list-group-item>
                <b-list-group-item>
                    <p>贤明调解工作室成立于2016年，依法经上海市浦东新区民政局批准登记设立的民办非盈利机构，设立之初衷系响应党中央政策之号召，发扬枫桥经验，矛盾不上交，化解于基层，遂在当地政府的支持下，由黄贤明律师牵头成立了该调解工作室，旨在为当地政府于矛盾化解，溯源治理上尽绵薄之力。</p>
                    <p>工作室成立后，期初为主要以当地居民的家事纠纷的化解和调解为主，后经过不断的发展，调解工作惠及的区域逐渐扩大至周边街镇，调解业务的范围也从家事纠纷逐步扩展到其他各类纠纷，调解工作室地处上海浦东，在协助相关金融机构与借款人之间纠纷调解的工作中，积累了相当了的经验。</p>
                    <p>上海作为世界的金融中心，浦东又是上海的排头兵，贤明调解工作室在未来的工作中，将立足浦东，着力服务于基层的纠纷化解，借助属地化的优势，不断尝试拓展新的纠纷调解方式，积极配合法院及各机构，为保障经济发展，维护司法公证，促进社会和谐履行自己的职责！</p>
                </b-list-group-item>
            </b-list-group>
        </div>
        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                :filename="fileName"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="landscape"
                pdf-content-width="800px"
                :html-to-pdf-options="htmlToPdfOptions"

                ref="html2Pdf"
        >
            <section slot="pdf-content">
                <!-- PDF Content Here -->
                <div class="pdf-doc">
                    <div class="pdf-doc-title">
                        <h1 >上海浦东新区合庆镇贤明调解工作室</h1>
                        <p>{{ caseDetail.caseCode }}</p>
                        <hr>
                    </div>
                    <div class="pdf-doc-content">
                        <p>被申请人：{{ caseDetail.borrowerName }}</p>
                        <p>身份证号：{{ caseDetail.borrowerIdcardNo }}</p>
                        <p>&nbsp;</p>

                        <p class="case-content">
                            关于{{ caseDetail.loanServicePlatform }}与{{ caseDetail.borrowerName }}金融借贷合同纠纷一案，我工作室已正式受理，根据《中华人民共和国人民调解法》、《最高人民法院关于人民法院进一步深化多元化纠纷解决机制改革的意见》等规定，依法通知当事人进行诉前调解。调解员：{{ caseDetail.mediateUserName }}，调解工作室电话：{{ caseDetail.mediateUserPhone }}
                        </p>

                        <p>&nbsp;</p>
                        <div class="case-company">
                            <p>上海浦东新区合庆镇贤明调解工作室</p>
                            <p>{{ caseDetail.createTime }}</p>
                            <img src="@/assets/company-seal.png"/>
                        </div>
                    </div>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
	import axios from "axios";
	import VueHtml2pdf from 'vue-html2pdf'

	export default {
		name: "CaseDetail",
		data() {
			return {
				caseDetail: {
					"createTime": ""
                },
				fileName: "调解函",
				htmlToPdfOptions: {
					margin: 0,
					filename: "调解函001",
				}
			}
		},
		components: {
			VueHtml2pdf
		},
		filters: {
			dateFilters: function (value) {
				return value.substring(0,10)
            },
			statusFilters: function (value) {
				let val = ""

				switch (value) {
					case 0:
						val = "未调解"
						break
					case 1:
						val = "调解中"
						break
					case 2:
						val = "结案"
						break
					case 3:
						val = "冻结"
						break
				}
				return val
			},
		},
		created() {
			this.getCaseDetail()
		},
		methods: {
			getCaseDetail() {
				axios.get( process.env.VUE_APP_BASE_API + "/v1/caseInfo/detail"  + "?identityId=" + this.$route.query.identityId + "&caseId=" + this.$route.query.caseId).then(
					response => {
						this.caseDetail = response.data.data
                        this.htmlToPdfOptions.filename = response.data.data.caseCode
					},
					error => {
						console.log(error)
					}
				)
			},
			generateReport() {
				this.$refs.html2Pdf.generatePdf()
			},
		}
	}
</script>

<style scoped>
    >>>.list-group .active {
        background-color: #c5a660;
        border-color: #c5a660;
    }

    .list-group p {
        text-align: justify;
        text-indent: 2rem;
    }

    .row>.col-3:first-child {
        font-weight: bolder;
        text-align: right;
        padding-right: 0px;
        padding-left: 0px;
    }
    .padLR0 {
        padding-left:0;
        padding-right:0;
    }
    svg{
        margin-top: 0.5rem;
    }
    .line-table {
        border-right: 2px solid #0e93d7;
        border-bottom: 2px solid #0e93d7;
        width: 100%;
    }
    .line-table-2 {
        border-left: 2px solid #0e93d7;
        width: 100%;
        position: relative;
    }
    .line-table-2:before {
        content: "";
        position: absolute;
        border: 4px solid transparent;
        border-top-color: #0e93d7;
        left: -4px;
        bottom: -4px;
    }


    .pdf-doc {
        width: 800px;
        height: 1024px;
        margin: 0 auto;
    }
    .pdf-doc-title {
        text-align: center;
        color: #AD1728;
    }
    h1{
        margin-top: 80px;
        font-size: 40px;
        font-weight: bolder;
    }
    .pdf-doc-title p{
        font-size: 25px;
        color: black;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    hr {
        border: none;
        height: 2px;
        width: 90%;
        background-color: #AD1728;
    }
    .pdf-doc-content {
        margin:0 auto;
        width: 90%;
    }
    .pdf-doc-content h1 {
        text-align: center;
    }
    .pdf-doc-content p {
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 3rem;
    }
    .case-content {
        text-indent: 2rem;
    }
    .case-company {
        position: relative;
        text-align: center;
        float: right;
        margin-right: 4rem;
    }
    .case-company img {
        width: 180px;
        height: 180px;
        position: absolute;
        top: -4rem;
        left: 4rem;
    }

</style>