<template>
    <header>
        <div>
            <!--img src="../assets/logo.png" -->
            <h5>{{ appName }}</h5>
            <hr/>
        </div>
    </header>
</template>

<script>
	export default {
		name: "MediationHeader",
		data() {
			return {
				"appName": process.env.VUE_APP_NAME,
			}
		},
	}
</script>

<style scoped>
    img {
        width: 30%;
        height: 30%;
    }
    h5 {
        margin-top: 1rem;
    }
    hr {
        border: 2px solid #c5a660;
    }
</style>