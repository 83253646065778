<template>
    <div class="container-fluid">
        <h5>案件查询</h5>
        <div>
            <b-alert
                    :show="dismissCountDown"
                    dismissible
                    :variant=variant
                    @dismissed="dismissCountDown=0"
                    @dismiss-count-down="countDownChanged"
            >
                {{ alertMsg }}
            </b-alert>

            <b-form @submit="caseQuerySubmit" @reset="onReset">

                <b-form-group
                        label-cols="3"
                        id="input-group-1"
                        label="身份证："
                        label-for="input-1"
                        :state="idcardState"
                >
                    <b-form-input
                            :state="idcardState"
                            id="input-1"
                            v-model="caseQueryForm.idcard"
                            type="text"
                            required
                            placeholder="输入被申请人身份证号"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                        label-cols="3"
                        id="input-group-2"
                        label="手机号："
                        label-for="input-2"
                        :state="mobileState"
                >
                    <b-input-group>
                        <b-form-input
                                :state="mobileState"
                                id="input-2"
                                v-model="caseQueryForm.mobile"
                                type="tel"
                                required
                                placeholder="请输入手机号"
                                style="width: 9rem;"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="captchaBtnDisabled" variant="warning" @click="getCaptcha()">{{ captchaBtnText }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <b-form-group
                        label-cols="3"
                        id="input-group-3"
                        label="验证码："
                        label-for="input-3"
                        :state="captchaState"
                >
                    <b-form-input
                            :state="captchaState"

                            id="input-3"
                            v-model="caseQueryForm.captcha"
                            required
                            placeholder="请输入短信验证码"
                    ></b-form-input>
                </b-form-group>

                <b-button type="reset" variant="danger" style="margin-right: 1rem;">重置</b-button>
                <b-button type="submit" variant="primary">提交</b-button>

            </b-form>
        </div>
    </div>
</template>

<script>
	import axios from "axios";

	export default {
		name: "CaseQuery",
		data() {
			return {
				variant: "",
				alertMsg: "",
				dismissSecs: 5,
				dismissCountDown: 0,
				caseQueryForm: {
					"idcard": "",
					"mobile": "",
					"captcha": "",
				},
				captchaInputDisabled: false,
				captchaBtnDisabled: false,
				captchaBtnText: "获取验证码",
			}
		},
		computed: {
			idcardState() {
				return this.isIdcard(this.caseQueryForm.idcard) ? true : false
			},
			mobileState() {
				let mobileReg = /^1[3456789]\d{9}$/
				return mobileReg.test(this.caseQueryForm.mobile)
			},
			captchaState() {
				return this.caseQueryForm.captcha.length == 6 ? true : false
			},
		},
		methods: {
			checkInteger(number){
				var regu = "^[0-9]*$";
				var re = new RegExp(regu);
				if (re.test(number)) {
					return true;
				} else {
					return false;
				}
			},
			isIdcard(idcard) {
				if (idcard == null || idcard.length != 18) {
					return false;
				}

				var intArr = new  Array( 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
				var sum = 0;
				for (var i = 0; i < intArr.length; i++) {
					// 将这17位数字和系数相乘的结果相加。
					if (this.checkInteger(idcard.substring(i, i+1))==false)
						return false;
					sum += idcard.substring(i, i+1) * intArr[i];
				}
				// 用加出来和除以11，看余数是多少？
				var mod = sum % 11;
				// 余数只可能有0 1 2 3 4 5 6 7 8 9 10这11个数字。其分别对应的最后一位身份证的号码为1 0 X 9 8 7 6 5 4 3 2 。
				var intArr2 = new Array( 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 );
				var intArr3 = new Array(1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2);
				var matchDigit = "";
				for (let i = 0; i < intArr2.length; i++) {
					let j = intArr2[i];
					if (j == mod) {
						matchDigit = intArr3[i];
					}
				}
				if (matchDigit == (idcard.substring(idcard.length - 1))) {
					return true;
				} else {
					return false;
				}
			},
			getCaptcha() {
                if (!this.idcardState) {
					this.variant = "danger"
					this.alertMsg = "请输入正确的身份证号码!!!"
					this.showAlert()
                    return
                }
				if (!this.mobileState) {
					this.variant = "danger"
					this.alertMsg = "请输入正确的手机号码!!!"
					this.showAlert()
                    return
				}

				axios.post(process.env.VUE_APP_BASE_API + "/v1/caseInfo/getCaptcha", this.caseQueryForm).then(
					response => {
						if (Number(response.data.code) == 20000) {
							this.variant = "success"
							this.alertMsg = "短信验证码发送成功!!!"
							this.showAlert()

							let t = 60;
							let timer = setInterval( () => {
								t --;
								this.captchaBtnText = t;
								this.captchaInputDisabled = false;
								this.captchaBtnDisabled = true;
								if (t < 0) {
									this.captchaBtnText = "获取验证码";
									this.captchaInputDisabled = true;
									this.captchaBtnDisabled = false;
									clearInterval(timer);
								}
							}, 1000)
						} else {
							this.variant = "danger"
							this.alertMsg = "短信验证码发送失败!!!"
							this.showAlert()
						}
					},
					error => {
						this.variant = "danger"
						this.alertMsg = error
						this.showAlert()
					}
				)
			},
			showAlert() {
				this.dismissCountDown = this.dismissSecs
			},
			countDownChanged(dismissCountDown) {
				this.dismissCountDown = dismissCountDown
			},
			caseQuerySubmit(evt) {
				evt.preventDefault()
				if (!this.idcardState) {
					this.variant = "danger"
					this.alertMsg = "请输入正确的身份证号码!!!"
					this.showAlert()
					return
				}
				if (!this.mobileState) {
					this.variant = "danger"
					this.alertMsg = "请输入正确的手机号码!!!"
					this.showAlert()
					return
				}
				if (!this.captchaState) {
					this.variant = "danger"
					this.alertMsg = "请输入正确的短信验证码!!!"
					this.showAlert()
					return
				}

				axios.post(process.env.VUE_APP_BASE_API + "/v1/caseInfo/caseQuery", this.caseQueryForm).then(
					response => {
						if (Number(response.data.code) != 20000) {
							this.variant = "danger"
							this.alertMsg = response.data.message
							this.showAlert()
                            return
						}
						this.$router.push({path: '/case/list', query: {"identityId": response.data.data.token}})
					},
					error => {
						this.variant = "danger"
						this.alertMsg = error
						this.showAlert()
					}
				)

			},

			onReset(evt) {
				evt.preventDefault()
				this.caseQueryForm.idcard = ""
				this.caseQueryForm.mobile = ""
				this.caseQueryForm.captcha = ""
			}
		},
	}
</script>

<style scoped>
    h5 {
        margin-bottom: 1rem;
    }
</style>