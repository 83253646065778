<template>
    <div class="container-fluid">
        <h5 v-html="article.title"></h5>
        <div v-html="article.content"></div>
    </div>
</template>

<script>
	import axios from "axios"

	export default {
		name: "PhotoWall",
		components: {},
		data() {
			return {
				"article": {
					"title": "",
					"content": "",
				}
			}
		},
		methods: {
			getArticleDetail() {
				axios.get(process.env.VUE_APP_BASE_API + "/v1/article/detail" + "?id=7").then(
					response => {
						this.article.content = response.data.data.content
						this.article.title = response.data.data.title
					},
					error => {
						console.log(error)
					}
				)
			}
		},
		created() {
			this.getArticleDetail()
		},
	}
</script>

<style scoped>
    h5 {
        margin-bottom: 1rem;
    }
    .container-fluid >>> img {
        width: 90%;
        height: 90%;
    }
</style>