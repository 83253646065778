<template>
    <div id="app">
        <MediationHeader></MediationHeader>
        <router-view></router-view>
        <MediationFooter></MediationFooter>
    </div>
</template>

<script>
	import MediationHeader from "./components/MediationHeader"
	import MediationFooter from "./components/MediationFooter"

	export default {
		name: 'App',
		components: {
			MediationHeader,
			MediationFooter,
		}
	}
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 1rem;
    }
</style>
