<template>
    <div class="container-fluid">
        <h5>线上咨询/意见反馈</h5>
        <div>
            <b-alert
                    :show="dismissCountDown"
                    dismissible
                    :variant=variant
                    @dismissed="dismissCountDown=0"
                    @dismiss-count-down="countDownChanged"
            >
                {{ alertMsg }}
            </b-alert>

            <b-form @submit="feedbackSubmit" @reset="onReset">

                <b-form-group
                        label-cols="3"
                        id="input-group-1"
                        label="姓名："
                        label-for="input-1"
                        :invalid-feedback="invalidUserName"
                        :state="userNameState"
                >
                    <b-form-input
                            id="input-1"
                            v-model="feedbackForm.username"
                            type="text"
                            required
                            placeholder="请输入姓名"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                        label-cols="3"
                        id="input-group-2"
                        label="手机号："
                        label-for="input-2"
                        :invalid-feedback="invalidMobile"
                        :state="mobileState"
                >
                    <b-form-input
                            id="input-2"
                            v-model="feedbackForm.mobile"
                            type="tel"
                            required
                            placeholder="请输入手机号"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                        label-cols="3"
                        id="input-group-3"
                        label="咨询/反馈内容："
                        label-for="input-3"
                        :invalid-feedback="invalidContent"
                        :state="contentState"
                >
                    <b-form-textarea
                            id="input-3"
                            v-model="feedbackForm.content"
                            rows="3"
                            max-rows="10"
                            required
                            placeholder="请输入咨询/反馈内容"
                    ></b-form-textarea>
                </b-form-group>

                <b-button type="reset" variant="danger" style="margin-right: 1rem;">重置</b-button>
                <b-button type="submit" variant="primary">提交</b-button>

            </b-form>

        </div>
    </div>
</template>

<script>
	import axios from "axios";

	export default {
		name: "FeedBack",
		data() {
			return {
				variant: "",
				alertMsg: "",
				dismissSecs: 5,
				dismissCountDown: 0,
				feedbackForm: {
					"username": "",
					"mobile": "",
					"content": "",
				}
			}
		},
		computed: {
			userNameState() {
				return this.feedbackForm.username.length >= 3 ? true : false
			},
			invalidUserName() {
				if (this.feedbackForm.username.length < 3) {
					return "请输入姓名..."
				}
				return ""
			},
			mobileState() {
				let mobileReg = /^1[3456789]\d{9}$/
				return mobileReg.test(this.feedbackForm.mobile)
			},
			invalidMobile() {
				let mobileReg = /^1[3456789]\d{9}$/
				if ( !mobileReg.test(this.feedbackForm.mobile) ) {
					return "请输入正确的手机号..."
				}
				return ""
			},
			contentState() {
				return this.feedbackForm.content.length >= 5 ? true : false
			},
			invalidContent() {
				if (this.feedbackForm.content.length < 5) {
					return "请输入咨询/反馈内容..."
				}
				return ""
			},
		},
		methods: {
			showAlert() {
				this.dismissCountDown = this.dismissSecs
			},
			countDownChanged(dismissCountDown) {
				this.dismissCountDown = dismissCountDown
			},
			feedbackSubmit(evt) {
				evt.preventDefault()
				axios.post(process.env.VUE_APP_BASE_API + "/v1/addFeedback", this.feedbackForm).then(
					response => {
						if (Number(response.data.code) == 20000) {
							this.feedbackForm.username = ""
							this.feedbackForm.mobile = ""
							this.feedbackForm.content = ""
                            this.variant = "success"
                            this.alertMsg = "您的反馈我们已经收到!"
							this.showAlert()
                        } else {
							this.variant = "danger"
							this.alertMsg = response.data.message
							this.showAlert()
                        }
					},
					error => {
						console.log(error)
					}
				)
			},

			onReset(evt) {
				evt.preventDefault()
				this.feedbackForm.username = ""
				this.feedbackForm.mobile = ""
				this.feedbackForm.content = ""
			}
		},
	}
</script>

<style scoped>
    h5 {
        margin-bottom: 1rem;
    }
</style>