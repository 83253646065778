<template>
    <footer>
        <div>
            <hr/>
            <p v-if="contractNumber">联系电话：{{ contractNumber }}</p>
            <p v-if="icp">备案号：<a href="http://beian.miit.gov.cn/" target="_blank">{{ icp }}</a></p>
            <p class="copyright">Copyright @ {{ currentYear }} {{ appName }} 版权所有</p>
        </div>
    </footer>
</template>

<script>
	export default {
		name: "MediationFooter",
		data() {
			return {
				"currentYear": "",
				"appName": process.env.VUE_APP_NAME,
				"icp": process.env.VUE_APP_ICP,
				"contractNumber": process.env.VUE_APP_CONTACT_NUMBER,
			}
		},
		mounted() {
			this.getCurrentYear();
		},
		methods: {
			getCurrentYear() {
				let nowDate = new Date();
				let year = nowDate.getFullYear();
				this.currentYear = year;
			},
		}
	}
</script>

<style scoped>
    footer {
        font-size: 0.85rem;
    }
    hr {
        border: 1px solid #c5a660;
    }
    @media screen and (max-width: 400px) {
        .copyright {
            font-size: 0.8rem;
        }
    }
</style>