<template>
    <div class="container-fluid">
        <h2 style="color:#c5a660; margin:5rem auto;">404, 您的页面走丢了</h2>
    </div>
</template>

<script>
	export default {
		name: "NotFound"
	}
</script>

<style scoped>

</style>