<template>
    <div class="container-fluid">
        <h5>新闻列表</h5>
        <div>
            <b-list-group>
                <b-list-group-item style="text-align:left;" class="text-nowrap text-truncate" :to="{ path: '/news/detail', query: { 'id': item.id } }" v-for="item in newsList" :key="item.id" :title="item.title"><b-icon style="margin-right: 0.3rem;color: #c5a660;" icon="brightness-high"></b-icon>{{ item.title }}</b-list-group-item>
            </b-list-group>
        </div>
        <b-pagination
                @change="changePage"
                align="center"
                v-model="listQuery.page"
                :total-rows="total"
                :per-page="listQuery.limit"
                first-text="⏮"
                prev-text="⏪"
                next-text="⏩"
                last-text="⏭"
                class="mt-4"
        ></b-pagination>
    </div>
</template>

<script>
	import axios from "axios"

	export default {
		name: "NewsList",
		data() {
			return {
				newsList: [],
				total: 0,
				listQuery: {
					page: 1,
					limit: 10,
				},
			}
		},
		methods: {
			getNewsList() {
				let params = {
					"params": {
						"type": 3,
                        "page": this.listQuery.page,
                        "limit": this.listQuery.limit,
                    }
                }
				axios.get(process.env.VUE_APP_BASE_API + "/v1/article/list", params).then(
					response => {
						this.newsList = response.data.data.list
						this.total = response.data.data.total
						this.listQuery.page = response.data.data.page
						this.listQuery.limit = response.data.data.limit
					},
					error => {
						console.log(error)
					}
				)
			},
			changePage(page) {
				this.listQuery.page = page
				this.getNewsList()
            },
		},
		created() {
			this.getNewsList()
		},
	}
</script>

<style scoped>
    h5 {
        margin-bottom: 1rem;
    }

    .container-fluid >>> img {
        width: 90%;
        height: 90%;
    }

</style>