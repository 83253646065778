<template>
    <div class="container-fluid">
        <h5>案件列表</h5>
        <div>
            <b-list-group>
                <b-list-group-item style="text-align:left;" class="text-nowrap text-truncate" :to="{ path: '/case/detail', query: { 'identityId': $route.query.identityId, 'caseId': item.caseId } }" v-for="item in caseList" :key="item.caseId" :title="item.title">
                    <b-icon style="margin-right: 0.3rem;color: red;" icon="bell"></b-icon>
                    <b-icon style="margin-right: 0.3rem;color: red;" icon="bell-fill"></b-icon>
                    <b-icon style="margin-right: 0.3rem;color: red;" icon="bell"></b-icon>
                    <b-card
                        border-variant="warning"
                        header-bg-variant="transparent"
                        :header="`调解编号:` + item.caseNo"
                        align="center"
                    >
                        <b-card-text>申请人：{{ item.petitioner }}</b-card-text>
                        <b-card-text>被申请人：{{ item.respondent }}</b-card-text>
                        <b-card-text>提交日期：{{ item.caseDateTime.substring(0,10) }}</b-card-text>
                        <b-button variant="primary">查看详情</b-button>
                    </b-card>
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>

<script>
	import axios from "axios"

	export default {
		name: "CaseList",
		data() {
			return {
				caseList: [],
			}
		},
		methods: {
			getCaseList() {
				axios.get( process.env.VUE_APP_BASE_API + "/v1/caseInfo/list"  + "?identityId=" + this.$route.query.identityId).then(
					response => {
						this.caseList = response.data.data
					},
					error => {
						console.log(error)
					}
				)
			},
		},
		created() {
			this.getCaseList()
		},
	}
</script>

<style scoped>
    >>>.btn-primary:hover{
        background-color: #c5a660;
    }
    >>>.btn-primary{
        background-color: #c5a660;
        border-color: #c5a660;
    }
</style>